var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "baseInfo" },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "设备基本信息",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-save": _vm.headSave, "head-cancel": _vm.headCancel },
      }),
      _c(
        "div",
        { staticClass: "formContentBox" },
        [
          _c(
            "div",
            { staticClass: "formMain" },
            [
              _c(
                "avue-form",
                {
                  ref: "addForm",
                  attrs: { option: _vm.formOptions },
                  on: { submit: _vm.handleSubmit },
                  model: {
                    value: _vm.addForm,
                    callback: function ($$v) {
                      _vm.addForm = $$v
                    },
                    expression: "addForm",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "equipmentCode" },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            placeholder: "请输入记录编号",
                            maxlength: "64",
                            "show-word-limit": "",
                            disabled:
                              _vm.isAutomatic ||
                              _vm.pageDisabled ||
                              _vm.addForm.id,
                          },
                          model: {
                            value: _vm.addForm.equipmentCode,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "equipmentCode", $$v)
                            },
                            expression: "addForm.equipmentCode",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "append" }, slot: "append" },
                            [
                              _vm._v(
                                "\n              自动生成\n              "
                              ),
                              _c("el-switch", {
                                attrs: {
                                  disabled: _vm.pageDisabled || _vm.addForm.id,
                                  "active-color": "#13ce66",
                                },
                                on: { change: _vm.handleSwitch },
                                model: {
                                  value: _vm.isAutomatic,
                                  callback: function ($$v) {
                                    _vm.isAutomatic = $$v
                                  },
                                  expression: "isAutomatic",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "producer" },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "125",
                          title: _vm.addForm.producer,
                          disabled: _vm.formType == "view",
                        },
                        model: {
                          value: _vm.addForm.producer,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "producer", $$v)
                          },
                          expression: "addForm.producer",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "installationUnit" },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "125",
                          title: _vm.addForm.installationUnit,
                          disabled: _vm.formType == "view",
                        },
                        model: {
                          value: _vm.addForm.installationUnit,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "installationUnit", $$v)
                          },
                          expression: "addForm.installationUnit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "location" },
                    [
                      _c("el-input", {
                        attrs: {
                          maxlength: "125",
                          title: _vm.addForm.location,
                          disabled: _vm.formType == "view",
                        },
                        model: {
                          value: _vm.addForm.location,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "location", $$v)
                          },
                          expression: "addForm.location",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("template", { slot: "intervalCycle" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("el-input", {
                          staticClass: "input-with-select",
                          attrs: {
                            placeholder: "请输入维保周期",
                            disabled: _vm.formType == "view",
                          },
                          model: {
                            value: _vm.addForm.intervalCycle,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm, "intervalCycle", $$v)
                            },
                            expression: "addForm.intervalCycle",
                          },
                        }),
                        _c(
                          "el-select",
                          {
                            attrs: {
                              slot: "append",
                              placeholder: "请选择单位",
                              disabled: _vm.formType == "view",
                            },
                            slot: "append",
                            model: {
                              value: _vm.addForm.intervalUnit,
                              callback: function ($$v) {
                                _vm.$set(_vm.addForm, "intervalUnit", $$v)
                              },
                              expression: "addForm.intervalUnit",
                            },
                          },
                          _vm._l(_vm.intervalUnitData, function (item) {
                            return _c("el-option", {
                              key: item.dictKey,
                              attrs: {
                                label: item.dictValue,
                                value: item.dictKey,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "template",
                    { slot: "useOrgName" },
                    [
                      _c("el-input", {
                        ref: "useOrgName",
                        attrs: { disabled: _vm.formType == "view" },
                        on: { focus: _vm.handleChangeOrg },
                        model: {
                          value: _vm.addForm.useOrgName,
                          callback: function ($$v) {
                            _vm.$set(_vm.addForm, "useOrgName", $$v)
                          },
                          expression: "addForm.useOrgName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("template", { slot: "files" }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-between",
                        },
                      },
                      [
                        _vm.formType == "view"
                          ? _c(
                              "div",
                              _vm._l(_vm.fileList, function (item) {
                                return _c("el-image", {
                                  key: item.url,
                                  staticStyle: {
                                    height: "150px",
                                    display: "inline-block",
                                  },
                                  attrs: {
                                    src: item.url,
                                    previewSrcList: _vm.fileList.map(
                                      (val) => val.url
                                    ),
                                  },
                                })
                              }),
                              1
                            )
                          : _c(
                              "el-upload",
                              {
                                class: { uploadhide: _vm.pageDisabled },
                                attrs: {
                                  disabled: _vm.pageDisabled,
                                  action:
                                    "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                  "list-type": "picture-card",
                                  accept: ".jpg,.png",
                                  limit: 9,
                                  placeholder: "请上传图片",
                                  "file-list": _vm.fileList,
                                  headers: _vm.headers,
                                  "on-success": _vm.uploadSuccess,
                                  "on-remove": _vm.handleRemove,
                                  "on-preview": _vm.handlePreview,
                                  "on-exceed": _vm.uploadExceed,
                                  "on-change": _vm.uploadChange,
                                  "before-upload": _vm.uploadBefore,
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon",
                                }),
                                !_vm.pageDisabled
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "el-upload__tip",
                                        attrs: { slot: "tip" },
                                        slot: "tip",
                                      },
                                      [
                                        _vm._v(
                                          "\n                图片支持*.jpg/*.png格式,文件大小不超过20M\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                        _vm.qrcodeSrc
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "150px",
                                  height: "200px",
                                  "text-align": "center",
                                },
                              },
                              [
                                _c("el-image", {
                                  staticStyle: {
                                    width: "150px",
                                    height: "150px",
                                  },
                                  attrs: { src: _vm.qrcodeSrc },
                                }),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini" },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.downloadQrcode.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("点击放大/下载二维码")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "人员选择",
                visible: _vm.peopleDialogShow,
                width: "80%",
                top: "8vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.peopleDialogShow = $event
                },
              },
            },
            [
              _c("UserDetpDialog", {
                ref: "UserDetpDialog",
                attrs: { "dept-category": [2, 5] },
                on: { "select-data": _vm.selectPeopleData },
              }),
            ],
            1
          ),
          _vm.dialogVisible
            ? _c(
                "el-dialog",
                {
                  attrs: {
                    title: "预览",
                    visible: _vm.dialogVisible,
                    "append-to-body": "",
                    top: "5vh",
                    "custom-class": "customDialog",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("img", {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      "object-fit": "contain",
                    },
                    attrs: { src: _vm.dialogImageUrl, alt: "" },
                  }),
                ]
              )
            : _vm._e(),
          _c("qrcodeDialog", { ref: "qrcodeDialog" }),
          _c(
            "el-dialog",
            {
              attrs: { title: "部门选择", visible: _vm.deptShow, width: "80%" },
              on: {
                "update:visible": function ($event) {
                  _vm.deptShow = $event
                },
              },
            },
            [
              _c("DeptDialog", {
                ref: "DeptDialog",
                on: { "select-data": _vm.selectDept },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }