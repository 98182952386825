<template>
  <div>
    <head-layout
      head-title="检验记录"
      :head-btn-options="headBtnOptions"
      @head-add="headAdd"
      @head-del="headDel"
    ></head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
      @gird-handle-select-click="selectChange"
    >
      <template #documentUrl="{ row }">
        <img class="courseCoverImg" :src="row.imgUrl" />
      </template>
      <template #customBtn="{ row }">
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          @click="rowUpdate(row)"
          >编辑
        </el-button>
        <el-button
          style="margin: 0 3px"
          type="text"
          size="small"
          @click="rowDel(row)"
          >删除
        </el-button>
      </template>
    </grid-layout>
    <testRecordDialog
      ref="testRecordDialog"
      @callback="addCallback"
    ></testRecordDialog>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout.vue";
import GridLayout from "@/views/components/layout/grid-layout.vue";
import testRecordDialog from "./testRecordDialog.vue";
import { mapGetters } from "vuex";
import {
  eqLedgerTestRecordPage,
  eqLedgerTestRecordRemove,
} from "@/api/equipmentFacilities/equipmentLedger";
export default {
  components: {
    HeadLayout,
    GridLayout,
    testRecordDialog,
  },

  computed: {
    ...mapGetters(["permission", "colorName"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      // console.log(ids)
      return ids.join(",");
    },
    permissionList() {
      return {
        addBtn: this.vaildData(
          this.permission.equipmentMaintenance_maintain_add,
          true
        ),
        viewBtn: this.vaildData(
          this.permission.equipmentMaintenance_maintain_view,
          true
        ),
        editBtn: this.vaildData(
          this.permission.equipmentMaintenance_maintain_edit,
          true
        ),
        delBtn: this.vaildData(
          this.permission.equipmentMaintenance_maintain_delete,
          true
        ),
      };
    },

    headBtnOptions() {
      let btnList = [];
      if (this.permissionList.addBtn) {
        btnList.push({
          label: this.$t(`cip.cmn.btn.addBtn`),
          emit: "head-add",
          type: "button",
          icon: "",
        });
      }
      if (this.permissionList.delBtn) {
        btnList.push({
          label: this.$t(`cip.cmn.btn.delBtn`),
          emit: "head-del",
          type: "button",
          icon: "",
        });
      }
      return this.$route.query.type == "view" ? [] : btnList;
    },

    tableOptions() {
      return {
        selection: this.$route.query.type != "view",
        linklabel: "recordCode",
        menu: this.$route.query.type != "view",
        column: [
          {
            label: "检验单位",
            prop: "testOrg",
            align: "center",
            overHidden: true,
          },
          {
            label: "检验日期",
            prop: "testDate",
            align: "center",
            overHidden: true,
          },
          {
            label: "检验结果",
            prop: "testResult",
            align: "center",
            overHidden: true,
          },
          {
            label: "检验证明",
            prop: "documentUrl",
            align: "center",
            slot: true,
            overHidden: true,
          },
        ],
      };
    },
  },

  data() {
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      formType: "",
      typeId: "",
      equipmentLedgerId: "",
    };
  },

  created() {},

  methods: {
    init(type, typeId, id) {
      this.formType = type;
      this.typeId = typeId;
      this.equipmentLedgerId = id;
      this.onLoad(this.page, {});
    },

    headAdd() {
      this.$refs.testRecordDialog.init("add");
    },

    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.page = page;
      params.equipmentId = this.equipmentLedgerId;
      eqLedgerTestRecordPage({
        current: page.currentPage,
        size: page.pageSize,
        ...params
      }).then((res) => {
        const data = res.data.data;
        this.$refs.gridLayOut.page.total = data.total;
        this.tableData = data.records
          ? data.records.map((val) => {
              return {
                ...val,
                imgUrl: val.documentUrl
                  ? JSON.parse(val.documentUrl).length
                    ?JSON.parse(val.documentUrl)[0]?JSON.parse(val.documentUrl)[0].link
                    : ""
                  : "": "",
              };
            })
          : [];
        this.tableLoading = false;
        // this.$refs.gridLayOut.selectionClear()
        this.$nextTick(() => {
          this.$refs.gridLayOut.$refs.grid.$refs.table.doLayout();
          this.$refs.gridLayOut.$refs.grid.refreshTable();
        });
      });
    },

    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.onLoad(this.page);
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.onLoad(this.page);
    },

    selectChange(list) {
      this.selectionList = list;
    },
    rowView(row) {
      this.$router.push({
        path: "/equipmentFacilities/equipmentMaintenance/maintain/form",
        query: {
          row: JSON.stringify({ id: row.id }),
          type: "view",
        },
      });
    },
    rowUpdate(row) {
      this.$refs.testRecordDialog.init("edit", row);
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return eqLedgerTestRecordRemove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    headDel() {
      let selectionList = this.$refs.gridLayOut.selectionList;
      if (selectionList.length === 0) {
        this.$message.warning(this.$t(`cip.cmn.msg.warning.selectWarning`));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          return eqLedgerTestRecordRemove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    addCallback() {
      this.page.currentPage = 1;
      this.onLoad(this.page, {});
    },
  },
};
</script>

<style lang="scss" scoped>
.courseCoverImg {
  width: 80px;
  padding: 5px;
}
</style>
