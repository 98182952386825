var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "证书资料",
          showIcon: false,
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-add": _vm.headAdd, "head-romve": _vm.headRomve },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOption,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectionChange,
        },
        scopedSlots: _vm._u([
          {
            key: "materialUrl",
            fn: function ({ row }) {
              return [
                _c(
                  "el-link",
                  {
                    attrs: {
                      type: "primary",
                      href: _vm.getFile(row.materialUrl, "url"),
                    },
                  },
                  [_vm._v(_vm._s(_vm.getFile(row.materialUrl, "name")))]
                ),
              ]
            },
          },
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "0 3px" },
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.rowEdit(row, "edit")
                      },
                    },
                  },
                  [_vm._v("编辑")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "0 3px" },
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.rowRemove(row)
                      },
                    },
                  },
                  [_vm._v("删除")]
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "证书资料",
            visible: _vm.dialogVisible,
            width: "50%",
            "before-close": _vm.handleClose,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "avue-form",
            {
              ref: "addForm",
              attrs: { option: _vm.formOption },
              on: { submit: _vm.handleSubmit },
              model: {
                value: _vm.addForm,
                callback: function ($$v) {
                  _vm.addForm = $$v
                },
                expression: "addForm",
              },
            },
            [
              _c(
                "template",
                { slot: "materialUrl" },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        action:
                          "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                        headers: { Authorization: this.$store.getters.token },
                        "on-success": _vm.onSuccess,
                        accept: ".zip,.doc,.docx,.pdf,.jpg,.png",
                        "file-list": _vm.fileList,
                        "on-remove": _vm.onRemove,
                      },
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _vm._v(
                            "\n              支持扩展名：.zip,.doc,.docx,.pdf,.jpg,.png\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small" },
                  on: { click: _vm.handleClose },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                      "\n      "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.dialogLoading,
                      expression: "dialogLoading",
                    },
                  ],
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.handleSave },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t(`cip.cmn.btn.defBtn`)) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }