var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("head-layout", {
        attrs: {
          "head-title": "检验记录",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: { "head-add": _vm.headAdd, "head-del": _vm.headDel },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.tableOptions,
          "table-data": _vm.tableData,
          "table-loading": _vm.tableLoading,
          "data-total": _vm.page.total,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.handleCurrentChange,
          "page-size-change": _vm.handleSizeChange,
          "page-refresh-change": _vm.onLoad,
          "gird-handle-select-click": _vm.selectChange,
        },
        scopedSlots: _vm._u([
          {
            key: "documentUrl",
            fn: function ({ row }) {
              return [
                _c("img", {
                  staticClass: "courseCoverImg",
                  attrs: { src: row.imgUrl },
                }),
              ]
            },
          },
          {
            key: "customBtn",
            fn: function ({ row }) {
              return [
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "0 3px" },
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.rowUpdate(row)
                      },
                    },
                  },
                  [_vm._v("编辑\n      ")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { margin: "0 3px" },
                    attrs: { type: "text", size: "small" },
                    on: {
                      click: function ($event) {
                        return _vm.rowDel(row)
                      },
                    },
                  },
                  [_vm._v("删除\n      ")]
                ),
              ]
            },
          },
        ]),
      }),
      _c("testRecordDialog", {
        ref: "testRecordDialog",
        on: { callback: _vm.addCallback },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }