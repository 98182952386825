var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "ledger-form" },
    [
      _c(
        "el-tabs",
        {
          attrs: { "tab-position": "left", type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "baseInfo" } },
            [
              _vm.activeName == "baseInfo"
                ? _c("baseInfo", {
                    ref: "baseInfo",
                    on: { addCallBack: _vm.addCallBack },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.equipmentLedgerId || _vm.currentId
            ? [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "证书资料", name: "certificateInfo" } },
                  [
                    _vm.activeName == "certificateInfo"
                      ? _c("certificateInfo", { ref: "certificateInfo" })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "检查记录", name: "checkRecord" } },
                  [
                    _vm.activeName == "checkRecord"
                      ? _c("checkRecord", { ref: "checkRecord" })
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "保养记录", name: "maintainRecord" } },
                  [
                    _c("maintainRecord", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName == "maintainRecord",
                          expression: "activeName == 'maintainRecord'",
                        },
                      ],
                      ref: "maintainRecord",
                    }),
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "维修记录", name: "serviceRecord" } },
                  [
                    _c("serviceRecord", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeName == "serviceRecord",
                          expression: "activeName == 'serviceRecord'",
                        },
                      ],
                      ref: "serviceRecord",
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _vm.specialFlag == 1 && (_vm.equipmentLedgerId || _vm.currentId)
            ? [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "检验记录", name: "testRecord" } },
                  [
                    _vm.activeName == "testRecord"
                      ? _c("testRecord", { ref: "testRecord" })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }